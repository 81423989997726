import {useMemo, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import {Button, AnchorTag, Logo} from 'components'
import {Email, Phone, Telegram, WhatsApp, Instagram} from 'assets/icons'
import {useWindowSize} from 'utils'
import {
  blogAddress,
  instagramAddress,
  tabletScreen,
  telegramAddress,
  whatsAppAddress,
} from 'shared'

import style from './footer.module.scss'

function Footer() {
  const {t} = useTranslation()
  const {width} = useWindowSize()

  const tRef = useRef(t)

  const navItems = useMemo(
    () => [
      {
        id: 'aboutUs',
        value: tRef.current('navigation.aboutUs'),
        link: '/about-us',
      },
      {
        id: 'contactUs',
        value: tRef.current('navigation.contactUs'),
        link: '/contact-us',
      },
      {id: 'faq', value: tRef.current('navigation.faq'), link: '/faq'},
      {
        id: 'termsAndConditions',
        value: tRef.current('navigation.termsAndConditions'),
        link: '/terms-and-conditions',
      },
      {
        id: 'guide',
        value: tRef.current('navigation.guide'),
        link: '/guide',
      },
    ],
    [],
  )
  const blog = useMemo(
    () => ({
      id: 'blog',
      value: tRef.current('navigation.blog'),
      link: blogAddress,
    }),
    [],
  )
  const socials = useMemo(
    () => [
      {
        id: 'instagram',
        value: <Instagram />,
        link: instagramAddress,
        title: tRef.current('socialMedia.instagram'),
      },
      {
        id: 'telegram',
        value: <Telegram />,
        link: telegramAddress,
        title: tRef.current('socialMedia.telegram'),
      },
      {
        id: 'whatsApp',
        value: <WhatsApp />,
        link: whatsAppAddress,
        title: tRef.current('socialMedia.whatsApp'),
      },
    ],
    [],
  )

  return (
    <footer className={style['site-footer']}>
      <div className={style.container}>
        <div className={style['start-container']}>
          <div className={style['top-container']}>
            <div className={style.logo}>
              <Logo />
            </div>
            <div className={style['text-container']}>
              <h3 className={style['text-title']}>
                {tRef.current('footer.title')}
              </h3>
              <p className={style['text-description']}>
                {tRef.current('footer.description')}
              </p>
            </div>
          </div>
          {width > tabletScreen && <div className={style.line} />}
          <div className={style['button-container']}>
            <Button
              text={tRef.current('details.telephone')}
              className={`secondary direction-ltr ${style.telephone} ${style.button}`}
              icon={<Phone />}
              onClick={() =>
                (window.location.href = `tel:${tRef.current(
                  'details.telephone',
                )}`)
              }
            />
            <Button
              text={tRef.current('details.email')}
              className={`secondary-outline  ${style.button}`}
              icon={<Email />}
              onClick={() =>
                (window.location.href = `mailto:${tRef.current(
                  'details.email',
                )}`)
              }
            />
          </div>
        </div>
        {width <= tabletScreen && <div className={style.line} />}
        <div className={style['end-container']}>
          <ul className={`${style.list} ${style.navigation}`}>
            {navItems.map(item => (
              <li className={style.item} key={item.id}>
                <Link to={item.link}>{item.value}</Link>
              </li>
            ))}
            <li className={style.item}>
              <AnchorTag
                title={blog.value}
                content={blog.value}
                target="_blank"
                href={blog.link}
              />
            </li>
          </ul>
          <div className={style.line} />
          <div className={style.social}>
            <span className={style.text}>
              {tRef.current('details.followUs')}
            </span>
            <ul className={`${style.list} ${style['social-icons']}`}>
              {socials.map(social => (
                <li className={style['social-item']} key={social.id}>
                  <AnchorTag
                    title={social.title}
                    content={social.value}
                    target="_blank"
                    href={social.link}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
